import * as React from 'react';
import {useField} from 'formik';

import {Select, SelectProps} from './Select';

export const SelectFormik = ({name, value = ``, ...props}: SelectProps) => {
    const [field, meta, helpers] = useField(name);

    return (
        <Select
            {...props}
            {...field}
            error={meta.touched && meta.error}
            onChange={(val) => {
                helpers.setTouched(true);
                helpers.setValue(val.target.value);
            }}
        />
    );
};
