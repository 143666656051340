import * as React from 'react';

import {ContentModal} from '../ui/modals/ContentModal';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface ErrorModalProps {
    errorMsg: string;
    onClose: () => void;
    show: boolean;
}

export const ErrorModal = ({errorMsg, onClose, show}: ErrorModalProps) => (
    <ContentModal
        footer={
            <button
                className="btn btn-modal-cancel"
                onClick={onClose}
                type="button"
            >
                Close
            </button>
        }
        onClose={onClose}
        show={show}
        title=""
    >
        <>
            <h4>
                <FontAwesomeIcon icon={faExclamationTriangle} /> Sorry, {errorMsg}
            </h4>
            <div>
                Please contact your Dedicated Account Advisor at <strong>1-800-558-2808</strong>&nbsp; if you need assistance
            </div>
        </>
    </ContentModal>
);
